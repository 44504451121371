<template>
  <ul class="large header__mobile-nav__dropdowns">
    <li v-for="(item, i) in items" :key="i" :class="{ 'is-open' : openIndex === i }">
      <template v-if="subMenu == 'collections' || item.show_featured_collections_menu">
       <button @click="toggleDropdown(i)">
         {{ item.title | getTitle }}
         <SvgIcon name="chevron-select" />
        </button>

        <SlideUpDown :active="openIndex === i">
          <ul>
            <template v-if="item[subMenu]">
              <li v-for="(subItem, i) in item[subMenu]" :key="i">
                <PrismicLink
                  v-if="subItem[subMenuProp]"
                  :link="subItem[subMenuProp]"
                  :text="subItem[subMenuProp].title | getTitle" />
              </li>
            </template>
            <li v-if="item.link"><nuxt-link :to="item.link">View All</nuxt-link></li>
            <li v-else-if="item._meta"><nuxt-link :to="`/shop/${type}/${item._meta.uid}`">View All</nuxt-link></li>
          </ul>
        </SlideUpDown>
      </template>
      <PrismicLink v-else :link="item" :text="item.title | getTitle" />
    </li>

    <li v-if="link"><PrismicLink :link="link" text="View All" /></li>
  </ul>
</template>

<script>
  import SlideUpDown from 'vue-slide-up-down';
  import ArrowLink from '@/components/global/ArrowLink';
  import PrismicLink from '@/components/prismic/PrismicLink';
  import SvgIcon from '@/components/global/SvgIcon';


  export default {
    name: 'MobileSecondaryMenuItems',

    components: {
      SlideUpDown,
      ArrowLink,
      PrismicLink,
      SvgIcon
    },

    props: {
      type: {
        type: String,
      },

      items: {
          type: Array,
          required: true,
      },

      subMenu: {
        type: String
      }
    },

    data() {
      return {
        openIndex: null
      }
    },

    computed: {
        subMenuProp() {
            if (this.subMenu === 'featured_collections') return 'collection'

            return 'item'
        }
    },

    methods: {
      toggleDropdown(index) {
        this.openIndex = index === this.openIndex ? null : index;
      }
    }
  }
</script>
