<template>
  <div v-if="showCallout" class="icon-callout">
    <div>
      <SvgIcon :name="icon" />
    </div>
    <p>
      {{ text }}
      <template v-if="type !== 'Makeup Alert'">
        <PrismicLink v-if="isShop" :link="shopLink" text="Learn More" class="plain-link" />
        <component v-else :is="link ? 'nuxt-link' : 'button'" @click="handleClick" :to="link" class="plain-link">{{ linkText }}</component>
      </template>
    </p>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  import SvgIcon from '@/components/global/SvgIcon';
  import PrismicLink from '@/components/prismic/PrismicLink';

  export default {
    name: 'IconCallout',

    components: {
      SvgIcon,
      PrismicLink
    },

    props: {
      type: {
        type: String,
        default: null
      }
    },

    computed: {
      ...mapState({
        quizText: state => state.globalData.quiz_icon_callout_text,
        questionsText: state => state.globalData.questions_icon_callout_text,
        makeupAlertText: state => state.globalData.makeup_alert_icon_callout_text,
        shopLink: state => state.globalData.shop_icon_callout_link
      }),
      showCallout() {
        return this.type == 'Makeup Alert' ? this.makeupAlertText : this.type;
      },
      icon() {
        switch (this.type) {
          case 'Quiz':
          case 'Questions':
            return 'question';
          case 'Makeup':
            return 'g';
          case 'Makeup Alert':
            return 'alert';
          default:
            return 'check';
        }
      },
      text() {
        switch (this.type) {
          case 'Quiz':
            return this.quizText;
          case 'Questions':
            return this.questionsText;
          case 'Makeup':
            return 'Our makeup line is all made and tested by our Gee women.';
          case 'Makeup Alert':
            return this.makeupAlertText;
          default:
            return `Our ${this.type} brands are all curated and Gee approved.`;
        }
      },
      link() {
        switch (this.type) {
          case 'Quiz':
            return '/skincare-quiz';
          case 'Questions':
            return null;
        }
      },
      linkText() {
        switch (this.type) {
          case 'Quiz':
            return 'Start Here';
          case 'Questions':
            return 'Contact Us';
          default:
            return 'Learn More';
        }
      },
      isShop() {
        return this.type == 'Makeup' || this.type == 'Skincare' || this.type == 'Body + Wellness';
      }
    },

    methods: {
      ...mapActions([
        'openContactModal'
      ]),
      handleClick() {
        if(this.type == 'Questions') {
          this.openContactModal();
        }
      }
    }
  }
</script>
