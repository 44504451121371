<template>
<div>
    <h2>{{ label }}</h2>
    <ul class="large">
        <li v-if="menuItem[0].type == 'studios'">
            <nuxt-link :to="localStudioServiceLink">
              Services
            </nuxt-link>
        </li>
        <li v-for="(link, i) in links" :key="i">
            <nuxt-link v-if="link.type == 'studios'" :to="`/studio/${link._meta.uid}`">
                 {{ link.title | getTitle }}
            </nuxt-link>

            <component
                v-else
                :is="link.to ? 'nuxt-link' : 'button'"
                :to="link.to"
                @click="link.to ? '' : openHeaderMobileSubmenu(link.type)">
                    {{ link.mobileTitle ? link.mobileTitle : link.title | getTitle }}
              </component>

            <!-- <button v-else @click="openHeaderMobileSubmenu(link.type)">
                {{ link.title | getTitle }}
            </button> -->
        </li>
    </ul>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'MobileMenuItem',

    props: {
        label: String,
        menuItem: Array,
    },

    computed: {
        ...mapGetters([
          'localStudioServiceLink'
        ]),
        links() {
            if (!this.menuItem[0].links) return this.menuItem

            return this.menuItem[0].links
        }
    },

    methods: {
        ...mapActions([
            'openHeaderMobileSubmenu',
            'closeHeaderMobileSubmenu'
        ]),
    }
}
</script>
