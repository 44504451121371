<template>
  <div class="header__mobile-nav__view">
    <div>
      <h2>{{ label | capitalize }}</h2>

      <MobileSecondaryMenuItems
        :type="activeMobileSubmenu"
        :items="primaryMenu"
        :link="link"
        subMenu="featured_collections"
      />

      <MobileSecondaryMenuItems
        v-if="hasCollectionDropdowns"
        :type="activeMobileSubmenu"
        :items="collectionsMenu"
        subMenu="collections"
      />

      <ul v-if="bottomMenu">
        <li v-for="(item, i) in bottomMenuItems" :key="i">
          <PrismicLink
            :link="item.collection"
            :to="item.collection.title | getTitle"
            :class="{ red: item.is_red }" />
        </li>
      </ul>
    </div>
    <IconCallout v-if="isMakeup" type="Makeup Alert" />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  import { filterHiddenItems } from '@/services/MegaMenuService'

  import PrismicLink from '@/components/prismic/PrismicLink'
  import MobileSecondaryMenuItems from '@/components/header/expands/MobileSecondaryMenuItems'
  import IconCallout from '@/components/global/IconCallout'

  export default {
    name: 'MobileSecondaryMenu',

    components: { MobileSecondaryMenuItems, PrismicLink, IconCallout },

    props: {
      label: String,
      link: Object,
      primaryMenu: Array,
      hasCollectionDropdowns: {
        type: Boolean,
        default: false,
      },
      bottomMenu: Array,
      isMakeup: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState({
        isMobileSubmenuOpen: state => state.header.isMobileSubmenuOpen,
        activeMobileSubmenu: state => state.header.activeMobileSubmenu,
      }),

      filteredPrimaryMenu() {
        return filterHiddenItems(this.$store.state.locale, this.primaryMenu)
      },

      collectionsMenu() {
        if (!this.hasCollectionDropdowns) return null
        const menu = []

        if (this.link.featured_brands && this.link.featured_brands.length) {
          menu.push({
            title: 'By Brand',
            link: '/shop/brands',
            collections: [...this.link.featured_brands],
          })
        }

        if (
          this.link.featured_concerns &&
          this.link.featured_concerns.length &&
          !this.link.show_articles_not_concerns
        ) {
          menu.push({
            title: 'By Concern',
            link: '/shop/concerns',
            collections: [...this.link.featured_concerns],
          })
        }

        if (
          this.link.featured_articles &&
          this.link.featured_articles.length &&
          this.link.show_articles_not_concerns
        ) {
          menu.push({
            title: 'Articles',
            link: '/beauty-edit',
            collections: [...this.link.featured_articles],
          })
        }
        return menu
      },

      bottomMenuItems() {
        return this.bottomMenu.map(item => {
          if (this.link.__typename === 'Shop_category') return item
        })
      },
    },

    methods: {
      ...mapActions(['openHeaderMobileSubmenu', 'closeHeaderMobileSubmenu']),
    },
  }
</script>
