<template>
  <Expand type="mobile-nav" transition="slide-right" :class="{ 'is-submenu' : isMobileSubmenuOpen}">
    <template v-slot:top>
      <button v-if="isMobileSubmenuOpen" @click="closeHeaderMobileSubmenu" class="header__expand__back">
        <SvgIcon name="back" title="Back" />
      </button>
    </template>

    <template v-slot:content>
      <transition name="slide-next">
        <div v-if="!isMobileSubmenuOpen"
            class="header__mobile-nav__view">
            <MobileMenuItem
                v-for="(link,label,i) in links"
                :key="i"
                :label="label"
                :menuItem="link" />
        </div>
      </transition>

      <transition name="slide-prev">
        <template v-if="isMobileSubmenuOpen">
            <MobileSecondaryMenu
                v-for="(shopLink, i) in shopMenu"
                :key="i"
                :label="shopLink.title | getTitle"
                :link="shopLink"
                :primaryMenu="shopLink.productTypes"
                :hasCollectionDropdowns="shopLink.type !== 'makeup'"
                :bottomMenu="shopLink.highlights"
                :isMakeup="shopLink.type == 'makeup'"
            >
            </MobileSecondaryMenu>

          <div v-if="activeMobileSubmenu == 'about'" class="header__mobile-nav__view">
            <h2>About</h2>
            <PrismicMenu :menu="aboutMenu" class="large" />
            <PrismicMenu :menu="global.social_menu" />
          </div>
        </template>
      </transition>
    </template>
  </Expand>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  import Expand from '@/components/header/Expand';
  import SvgIcon from '@/components/global/SvgIcon';
  import MobileMenuItem from '@/components/header/expands/MobileMenuItem'
  import MobileSecondaryMenu from '@/components/header/expands/MobileSecondaryMenu'
  import PrismicMenu from '@/components/prismic/PrismicMenu'

  export default {
    name: 'MobileNav',

    components: {
      Expand,
      SvgIcon,
      MobileMenuItem,
      MobileSecondaryMenu,
      MobileSecondaryMenu,
      PrismicMenu,
    },

    computed: {
      ...mapState({
        global: state => state.globalData,
        links: state => state.header.links,
        activeMenu: state => state.header.activeMenu,
        isMobileSubmenuOpen: state => state.header.isMobileSubmenuOpen,
        activeMobileSubmenu: state => state.header.activeMobileSubmenu
      }),

      shopMenu() {
        return this.links.shop.filter(({ type }) => {
          return type === this.activeMobileSubmenu;
        });
      },

      aboutMenu() {
        return this.links.learn.find(({ type }) => {
          return type === 'about';
        }).menu;
      }
    },

    methods: {
      ...mapActions([
        'openHeaderMobileSubmenu',
        'closeHeaderMobileSubmenu'
      ])
    }
  }
</script>
